<template>
  <b-row>
    <b-col md="2" sm="4" class="my-1">
      <b-form-group class="mb-0">
        <label class="d-inline-block text-sm-left mr-50"></label>
        <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" class="w-50" />
      </b-form-group>
    </b-col>
    <b-col md="6" sm="8" class="my-1">

    </b-col>
    <b-col md="4" class="my-1">
      <b-form-group label="" label-cols-sm="3" label-align-sm="right" label-size="sm" label-for="filterInput"
        class="mb-0">
        <b-input-group size="sm">
          <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" />
        </b-input-group>
      </b-form-group>
    </b-col>
    <b-col cols="12">
      <b-table striped hover responsive :per-page="perPage" :current-page="currentPage" :items="items" :fields="fields"
        :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" :filter="filter"
        :filter-included-fields="filterOn" @filtered="onFiltered">
        <template #cell(wbs)="data">
          <b-row>
            <b-col md="2">
              <small class="text-primary">Kode Kegiatan</small>
              <h6 class="text-primary"><strong>{{ data.item.kode_kegiatan }}</strong></h6>
            </b-col>
            <b-col md="7">
              <small class="text-primary">Nama Kegiatan</small>
              <h6 class="text-primary"><strong>{{ data.item.nama_kegiatan }}</strong></h6>
            </b-col>
            <b-col md="3">
              <h6><sup>Rp. </sup><strong style="float: right;">{{ Rp(data.item.pagu_usulan) }}</strong></h6>

            </b-col>
          </b-row>
          <b-alert variant="primary" show>
            <div class="alert-body">
              <b-row v-for="sub_kegiatan in data.item.sub_kegiatan" v-bind:key="sub_kegiatan">
                <b-col md="2">
                  <h6 class="text-primary"> <small>{{ sub_kegiatan.kode_kegiatan }}</small> </h6>
                </b-col>
                <b-col md="8">
                  <h6 class="text-primary"><small> {{ sub_kegiatan.nama_sub_kegiatan }} </small></h6>
                </b-col>
                <b-col md="2">
                  <h6><small><sup>Rp. </sup><strong style="float: right;">{{ Rp(sub_kegiatan.pagu_usulan)
                  }}</strong></small></h6>

                </b-col>
              </b-row>
            </div>
          </b-alert>
        </template>
      </b-table>
    </b-col>

    <b-col cols="12">
      <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="right" size="sm"
        class="my-0" />
    </b-col>
  </b-row>
</template>

<script>
import {
  BTable,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton, BAlert,
} from 'bootstrap-vue'

export default {
  components: {
    BTable, BAlert,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
  },
  data() {
    return {
      perPage: 5,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [{
        key: 'wbs',
        label: '',
        thStyle: {
          display: 'none'
        }
      }],
    }
  },
  props: {
    items: {}
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({
          text: f.label,
          value: f.key
        }))
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
  },
  methods: {
    Rp(value) {
      let val = (value / 1).toFixed(0).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: index`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>
<style>
.btn-block {
  display: block;
  width: 100%;
}
</style>